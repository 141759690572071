/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Images
import Logo from 'img/Logo'
import Menu from 'img/Menu'

// Third Party
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useKeycloak from 'hooks/useKeycloak'

const StyledHeader = styled(motion.nav)`
  background-color: ${(props) => props.theme.color.light};
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  z-index: 50;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 991px) {
    height: 85px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

// const BlueBar = styled.div`
//   background-color: ${(props) => props.theme.color.primary};
//   height: 10px;
//   position: fixed;
//   left: 0;
//   right: 0;
//   top: 0;
//   z-index: 51;
// `

const Brand = styled.a`
  & > svg {
    height: auto;
    width: 180px;
  }

  @media screen and (max-width: 1199px) {
    & > svg {
      height: auto;
      width: 150px;
    }
  }

  @media screen and (max-width: 576px) {
    & > svg {
      height: auto;
      width: 100px;
    }
  }
`

const Title = styled.h1`
  font-size: 30px;
  font-weight: ${(props) => props.theme.font.weight.extraBold};
  color: ${({ theme }) => theme.color.primary};

  @media (max-width: 991px) {
    font-size: 20px;
    margin-top: 5px;
  }
`

const MenuIconWrapper = styled.div`
  display: none;

  @media (max-width: 991px) {
    display: block;
    margin-right: 10px;
  }
`

interface HeaderProps {
  setIsScrollable?: Function
  isSticky?: boolean
  setOpen: Function
}

const Header: React.FC<HeaderProps> = ({ setOpen }) => {
  const keycloak: any = useKeycloak()

  return (
    <>
      {/* <BlueBar /> */}
      <StyledHeader>
        <Container className="container">
          <div className="row w-100">
            <div className="col-lg-6">
              <Brand href="/">
                <Logo />
              </Brand>
            </div>
            <div className="col-lg-6">
              <div className="d-flex justify-content-lg-end align-items-center">
                <Title className="mb-0">{`Welkom, ${keycloak.client.tokenParsed.given_name} ${keycloak.client.tokenParsed.family_name}`}</Title>
              </div>
            </div>
          </div>
          <MenuIconWrapper onClick={() => setOpen(true)}>
            <Menu />
          </MenuIconWrapper>
        </Container>
      </StyledHeader>
    </>
  )
}

export default Header
