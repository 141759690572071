import React from 'react'

// Third Party
import styled, { css } from 'styled-components'

const StyledSideBarButton = styled.div<{ selected: boolean }>`
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 15px 0;
  font-size: 18px;
  font-weight: ${(props) => props.theme.font.weight.bold};
  outline: none;
  display: flex;

  & svg {
    max-height: 40px;
    height: 100%;
    width: 100%;
  }

  ${(props) =>
    props.selected
      ? css`
          background-color: ${props.theme.color.primaryLight};
          color: ${props.theme.color.primary};

          & svg {
            fill: ${props.theme.color.primary} !important;

            path {
              fill: ${props.theme.color.primary};
            }

            path.no-fill-change {
              fill: none !important;
            }
          }
        `
      : css`
          color: ${props.theme.color.primary};
          opacity: 0.4;

          & svg {
            fill: ${props.theme.color.primary} !important;

            path {
              fill: ${props.theme.color.primary};
            }

            path.no-fill-change {
              fill: none !important;
            }
          }
        `}

  @media (max-width: 1199px) {
    padding: 15px 0;
    margin: 0;
  }

  @media (max-width: 575px) {
    padding: 10px 0;
    margin: 0;
  }
`

interface SideBarButtonProps {
  path: string
  title: string
  location: any
  history: any
  icon: any
  setOpen: Function
}

const SideBarButton: React.FC<SideBarButtonProps> = ({ setOpen, icon, path, title, location, history }) => (
  <StyledSideBarButton
    selected={location.pathname === path}
    tabIndex={-1}
    role="button"
    onKeyDown={() => {
      history.push(path)
      setOpen(false)
    }}
    onClick={() => {
      history.push(path)
      setOpen(false)
    }}
  >
    <div className="row w-100 ps-xl-5 ps-4">
      <div className="col-2 d-flex justify-content-center align-items-center">{icon}</div>
      <div className="col-9 py-2">{title}</div>
    </div>
  </StyledSideBarButton>
)

export default SideBarButton
