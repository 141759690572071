import * as React from 'react'

function Persons() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.997" height="22.744" viewBox="0 0 28.997 22.744">
      <defs>
        <clipPath id="clipPath">
          <path id="Clip_4" data-name="Clip 4" d="M0,0H29V11.294H0Z" transform="translate(0.003 0.029)" fill="none" />
        </clipPath>
      </defs>
      <g id="_001-friends_2" data-name="001-friends 2" transform="translate(-0.003 -0.016)">
        <g id="_001-friends" data-name="001-friends">
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M5.293,11.333a5.5,5.5,0,0,0,5.293-5.666A5.5,5.5,0,0,0,5.293,0,5.5,5.5,0,0,0,0,5.666a5.5,5.5,0,0,0,5.293,5.666"
            transform="translate(3.505 0.016)"
            fill="#b3b4b4"
          />
          <path
            id="Fill_2"
            data-name="Fill 2"
            d="M4.547,9.711h.037a4.373,4.373,0,0,0,3.222-1.47A5,5,0,0,0,9.088,4.818,4.715,4.715,0,0,0,4.54,0H4.5a4.373,4.373,0,0,0-3.222,1.47A5,5,0,0,0,0,4.893a5,5,0,0,0,1.338,3.4A4.373,4.373,0,0,0,4.547,9.711"
            transform="translate(16.292 0.827)"
            fill="#b3b4b4"
          />
          <g id="Group_6" data-name="Group 6" transform="translate(0 11.437)">
            <path
              className="no-fill-change"
              id="Clip_4-2"
              data-name="Clip 4"
              d="M0,0H29V11.294H0Z"
              transform="translate(0.003 0.029)"
              fill="none"
            />
            <g id="Group_6-2" data-name="Group 6" clipPath="url(#clipPath)">
              <path
                id="Fill_3"
                data-name="Fill 3"
                d="M5.765,0h-.44A7.873,7.873,0,0,0,0,2.074a10.266,10.266,0,0,1,1.007.893A10.3,10.3,0,0,1,3.2,6.25,10.335,10.335,0,0,1,3.99,9.729h9.719V8.02A7.992,7.992,0,0,0,5.765,0"
                transform="translate(15.291 0.029)"
                fill="#b3b4b4"
              />
              <path
                id="Fill_5"
                data-name="Fill 5"
                d="M14.156,1.7A8.44,8.44,0,0,0,9.063,0H8.533A8.575,8.575,0,0,0,0,8.616V9.638H17.6V8.616q0-.274-.017-.543A8.625,8.625,0,0,0,14.156,1.7"
                transform="translate(0 1.685)"
                fill="#b3b4b4"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default Persons
