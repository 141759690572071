import React from 'react'

// Third Party
import styled from 'styled-components'

const WhiteBar = styled.div`
  padding: 25px 0;

  & h2 {
    font-size: 20px;
    font-weight: ${(props) => props.theme.font.weight.extraBold};
    color: ${(props) => props.theme.color.primary};
    margin: 0;

    strong,
    b {
      color: ${(props) => props.theme.color.secondary};
    }
  }
`

const Footer = () => (
  <>
    <WhiteBar>
      <div className="container">
        <div className="d-flex justify-content-between" />
      </div>
    </WhiteBar>
  </>
)

export default Footer
