import { useContext } from 'react'

import { Context } from '../context/AdapterProvider'

const useAdapter = () => {
  const context = useContext(Context)

  return context
}

export default useAdapter
