import * as React from 'react'

function Auti() {
  return (
    <svg width="64" height="81" viewBox="0 0 64 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.11 0C24.6419 0.0933895 17.2614 1.62109 10.37 4.5C7.48998 5.68 5.70998 6.82995 5.70998 8.94995C5.70998 10.14 6.21998 11.7299 7.51998 14.6299C9.20998 18.4099 10.17 19.7699 12.22 19.8899C13.5728 19.8315 14.9012 19.5089 16.13 18.9399L17.08 18.5999C21.8454 16.6457 26.9306 15.5881 32.08 15.48C42.66 15.48 46.98 19.3599 46.98 28.8899V32.8899C41.2637 31.3307 35.3651 30.5406 29.44 30.54C11.55 30.54 -0.0200195 40.0999 -0.0200195 54.8799C-0.0200195 70.4999 11.49 80.21 29.98 80.21C43.72 80.21 54.7 75.91 59.93 72.21C62.56 70.29 63.13 69.1601 63.13 65.8301V28.5898C63.21 9.34984 53.03 0 32.11 0ZM31.18 65.72C24.29 65.72 16.07 63.7999 16.07 54.6599C16.07 47.8299 21.69 43.75 31.07 43.75C36.43 43.75 40.87 44.6501 46.97 46.3101V61.23C42.202 64.1097 36.7498 65.6601 31.18 65.72Z"
        fill="#283578"
      />
    </svg>
  )
}
export default Auti
