import React, { createContext } from 'react'

export const Context = createContext({})

export interface ToastProps {
  text: string
  time: number
  status: 'success' | 'error' | 'pending' | 'closed'
}

const ToastProvider = ({ children }: any) => {
  const [toast, setToast] = React.useState<ToastProps>({
    text: '',
    time: 0,
    status: 'closed'
  })

  React.useEffect(() => {
    if (toast.status !== 'closed') {
      setTimeout(() => {
        setToast({
          text: '',
          time: 0,
          status: 'closed'
        })
        // @ts-ignore
      }, toast.time)
    }
  }, [toast])

  return (
    <Context.Provider
      value={{
        toast,
        setToast
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ToastProvider
