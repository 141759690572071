import React, { useEffect, useState, createContext } from 'react'

// Hooks
import useKeycloak from 'hooks/useKeycloak'

export const Context = createContext({})

const AdapterProvider = ({ children }: any) => {
  const [user, setUser] = useState({})
  const keycloak: any = useKeycloak()

  useEffect(() => {
    getUser()
  }, [])

  const request = async (route = '', data = {}) => {
    const settings = {
      ...{
        method: 'GET',
        token: '',
        body: {}
      },
      ...data
    }

    try {
      // eslint-disable-next-line no-undef
      const fetchRequest = await fetch(`${process.env.REACT_APP_API_URL}/api/v1${route}`, {
        method: settings.method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${settings.token}`
        },
        body: settings.method === 'GET' ? null : JSON.stringify(settings.body)
      })

      if (fetchRequest.status !== 200) {
        return { status: fetchRequest.status }
      }

      const response = await fetchRequest.json()

      return { status: fetchRequest.status, data: response }
    } catch (error) {
      return { status: 422 }
    }
  }

  const WPrequest = async (route = '', data = {}) => {
    const settings = {
      ...{
        method: 'GET',
        token: process.env.REACT_APP_API_SECRET,
        body: {}
      },
      ...data
    }

    try {
      // eslint-disable-next-line no-undef
      const fetchRequest = await fetch(`${process.env.REACT_APP_API_URL}/api/v1${route}`, {
        method: settings.method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `${settings.token}`
        },
        body: settings.method === 'GET' ? null : JSON.stringify(settings.body)
      })

      if (fetchRequest.status !== 200) {
        return { status: fetchRequest.status }
      }

      const response = await fetchRequest.json()

      return { status: fetchRequest.status, data: response }
    } catch (error) {
      return { status: 422 }
    }
  }

  // @ts-ignore
  const call = async (route = '', data = {}, retry = true) => {
    const settings = {
      ...{
        method: 'GET',
        token: keycloak.client.token,
        delay: 0
      },
      ...data
    }

    // Start delay for use expierence
    const startTimer = new Date().getTime()

    // Check if token is expired
    const expired = await keycloak.client.isTokenExpired()

    // Update the access token and retry the request
    if (expired && retry) {
      await keycloak.client.updateToken(30)

      return call(route, data, false)
    }

    // Update access token if needed
    const response = await request(route, settings)
    const difference = new Date().getTime() - startTimer
    const delay = settings.delay - difference

    // Continue delay if request was too fast
    if (delay > 0) {
      await new Promise((r) => setTimeout(r, delay))
    }

    return response
  }

  const getOpleidingen = async () => {
    // Get all Opleidingen
    const opleidingenRequest = await call(`/salesforce/opleidingen/${keycloak.client.tokenParsed.salesforce_id}`)

    if (opleidingenRequest.status !== 200) {
      return opleidingenRequest.status
    }

    return opleidingenRequest.data
  }

  const getWerkervaringen = async () => {
    // Get all werkervaringen
    const werkervaringRequest = await call(`/salesforce/werkervaringen/${keycloak.client.tokenParsed.salesforce_id}`)

    if (werkervaringRequest.status !== 200) {
      return werkervaringRequest.status
    }

    return werkervaringRequest.data
  }

  const getVaardigheden = async () => {
    // Get all vaardigheden
    const vaardigheidRequest = await call(`/salesforce/vaardigheden/${keycloak.client.tokenParsed.salesforce_id}`)

    if (vaardigheidRequest.status !== 200) {
      return vaardigheidRequest.status
    }

    return vaardigheidRequest.data
  }

  const getTalen = async () => {
    // Get all talen
    const taalRequest = await call(`/salesforce/talen/${keycloak.client.tokenParsed.salesforce_id}`)

    if (taalRequest.status !== 200) {
      return taalRequest.status
    }

    return taalRequest.data
  }

  const getInteresses = async () => {
    // Get all interesses
    const interesseRequest = await call(`/salesforce/interesses/${keycloak.client.tokenParsed.salesforce_id}`)

    if (interesseRequest.status !== 200) {
      return interesseRequest.status
    }

    return interesseRequest.data
  }

  const getAlerts = async () => {
    // Get all alerts
    const alertRequest = await WPrequest(`/alert/all/${keycloak.client.tokenParsed.salesforce_id}`, {
      method: 'GET',
      delay: 0
    })

    if (alertRequest.status !== 200) {
      return alertRequest.status
    }

    return alertRequest.data
  }

  const getUser: any = async () => {
    if (keycloak.client.tokenParsed.salesforce_id) {
      // eslint-disable-next-line no-undef
      const fetchRequest = await call(`/salesforce`)

      if (fetchRequest.status !== 200) {
        // return { status: fetchRequest.status }
        setUser({
          status: fetchRequest.status
        })
        return false
      }

      const [opleidingen, werkervaringen, vaardigheden, talen, interesses, alerts] = await Promise.all([
        getOpleidingen(),
        getWerkervaringen(),
        getVaardigheden(),
        getTalen(),
        getInteresses(),
        getAlerts()
      ])

      setUser({
        status: fetchRequest.status,
        data: fetchRequest.data,
        opleidingen,
        werkervaringen,
        vaardigheden,
        talen,
        interesses,
        alerts
      })

      if (fetchRequest.data.result.Name === 'Anonymized') {
        const deleteRequest = await request('/keycloak/delete', {
          method: 'GET',
          token: keycloak.client.token,
          delay: 0
        })

        if (deleteRequest.status === 201) {
          keycloak.client.logout()
        }
      }

      // Update last loggin time on Salesforce
      call('/salesforce/updatelastlogin')

      return true
    }

    // eslint-disable-next-line no-undef
    const checkRequest = await call(`/salesforce/contact`)

    if (checkRequest.status !== 200) {
      setUser({
        status: 'no_salesforce_id_found'
      })

      return false
    }

    const updateRequest = await request('/keycloak/update', {
      method: 'POST',
      token: keycloak.client.token,
      delay: 0,
      body: {
        id: checkRequest.data[0].Id
      }
    })

    if (updateRequest.status === 201) {
      await keycloak.client.updateToken(99999999999)

      return getUser()
    }

    setUser({
      status: 'no_salesforce_id_found'
    })

    return false
  }

  const updateAlerts: any = async () => {
    const alerts = await getAlerts()

    setUser({ ...user, alerts })
  }

  return (
    <Context.Provider
      value={{
        request,
        WPrequest,
        call,
        user,
        getUser,
        updateAlerts
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default AdapterProvider
