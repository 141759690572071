import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    primary: '#283578',
    secondary: '#F26640',
    primaryLight: '#B7D7E9',
    primaryLighter: 'rgba(183, 215, 233, .5)',
    secondaryLight: 'rgba(242, 102, 64,.3)',
    light: '#FFFFFF',
    dark: '#000000',
    darkLight: '#F0F0F0'
  },

  font: {
    family: {
      secondary: 'rubrik-edge-new, sans-serif',
      main: 'rubrik-edge-new, sans-serif'
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      xl: '32px',
      xxl: '45px',
      xxxl: '70px'
    },

    weight: {
      light: 200,
      regular: 300,
      bold: 700,
      extraBold: 800
    }
  }
}

const GlobalStyle = createGlobalStyle<{ whiteColor: boolean }>`
  body {
    font-family: ${(props) => props.theme.font.family.main};
  }
`

const Theme = ({ children }: any) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle whiteColor />
    {children}
  </ThemeProvider>
)

export default Theme
