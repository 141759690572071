import React from 'react'

// Hooks
import useKeycloak from 'hooks/useKeycloak'

// Third Party
import styled from 'styled-components'

interface NoAccountFoundProps {}

const StyledNoAccountFound = styled.section`
  padding: 100px 0;
  position: relative;
`

const LogoutButton = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};
  padding: 12px 80px;
  border-radius: 25px;
  font-weight: ${(props) => props.theme.font.weight.bold};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.color.primaryLight};
    color: ${(props) => props.theme.color.primary};
  }
`

const LogoutButtonContainer = styled.div`
  height: 88px;
`

const Title = styled.div`
  font-size: 40px;
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  color: ${({ theme }) => theme.color.secondary};
`

const Content = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.color.primary};
`

const SignupButton = styled.a`
  position: relative;
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.light};
  padding: 12px 80px;
  border-radius: 25px;
  font-weight: ${(props) => props.theme.font.weight.bold};
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.color.secondaryLight};
    color: ${(props) => props.theme.color.secondary};
  }
`

const NoAccountFound: React.FC<NoAccountFoundProps> = () => {
  const keycloak: any = useKeycloak()

  return (
    <StyledNoAccountFound>
      <div className="row justify-content-center">
        <div className="col-6">
          <Title>Helaas!</Title>
          <Content>
            Er lijkt geen inschrijving te zijn gevonden op het portaal, schrijf je nu in op onze website om toegang te krijgen tot jouw
            portaal
          </Content>
          <LogoutButtonContainer className="pt-5 d-flex align-items-center flex-no-wrap justify-content-center">
            <LogoutButton role="button" tabIndex={-1} onKeyDown={() => keycloak.client.logout()} onClick={() => keycloak.client.logout()}>
              Uitloggen
            </LogoutButton>
            <div className="mx-3">of</div>
            <SignupButton target="_blank" href="https://www.autitalent.nl/inschrijven">
              Inschrijven
            </SignupButton>
          </LogoutButtonContainer>
        </div>
      </div>
    </StyledNoAccountFound>
  )
}
export default NoAccountFound
