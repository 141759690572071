import React from 'react'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import useToast from 'hooks/useToast'

interface ToastProps {
  text: string
  status: 'success' | 'error' | 'pending' | 'closed'
}

const ToastContainer = styled(motion.section)`
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 50;
`

const StyledToast = styled.div<{ status: 'success' | 'error' | 'pending' | 'closed' }>`
  padding: 20px 30px;
  border-radius: 20px;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: center;
  cursor: pointer;
  max-width: 400px;

  ${({ status }) =>
    status === 'success' &&
    css`
      color: ${({ theme }) => theme.color.primary};
      background-color: ${({ theme }) => theme.color.primaryLight};
    `}

  ${({ status }) =>
    status === 'error' &&
    css`
      color: ${({ theme }) => theme.color.light};
      background-color: ${({ theme }) => theme.color.secondary};
    `}

  ${({ status }) =>
    status === 'pending' &&
    css`color: ${({ theme }) => theme.color.light};
      background-color: ${({ theme }) => theme.color.primary};`
  }
`

const Toast: React.FC<ToastProps> = ({ text, status }) => {
  const t: any = useToast()

  return (
    <ToastContainer initial={{ x: 80, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 80, opacity: 0 }}>
      <StyledToast
        onClick={() =>
          t.setToast({
            text: '',
            time: 0,
            status: 'closed'
          })
        }
        status={status}
      >
        {text}
      </StyledToast>
    </ToastContainer>
  )
}

export default Toast
