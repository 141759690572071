import React from 'react'
import { useHistory } from 'react-router'

// Third Party
import styled from 'styled-components'

const CustomLink = styled.div`
  outline: none;
`

interface ButtonShellProps {
  isAnchor?: boolean
  to: string
  children: any
  className?: string
}

const ButtonShell: React.FC<ButtonShellProps> = ({ isAnchor = false, to, children, className = '' }) => {
  const history = useHistory()

  return !isAnchor ? (
    <CustomLink role="button" tabIndex={-1} onClick={() => history.push(to)} className={className}>
      {children}
    </CustomLink>
  ) : (
    <a href={to} className={className}>
      {children}
    </a>
  )
}

export default ButtonShell
