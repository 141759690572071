import * as React from 'react'

function Hat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.216"
      height="20.971"
      viewBox="0 0 24.216 20.971"
    >
      <defs>
        <clipPath id="clipHat">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H24.216V20.971H0Z" transform="translate(0.003 0.026)" fill="none" />
        </clipPath>
      </defs>
      <g id="graduation-hat_2" data-name="graduation-hat 2" transform="translate(-0.003 -0.026)">
        <g id="graduation-hat">
          <g id="Group_3" data-name="Group 3">
            <path
              className="no-fill-change"
              id="Clip_2-2"
              data-name="Clip 2"
              d="M0,0H24.216V20.971H0Z"
              transform="translate(0.003 0.026)"
              fill="none"
            />
            <g id="Group_3-2" data-name="Group 3" clipPath="url(#clipHat)">
              <path
                id="Fill_1"
                data-name="Fill 1"
                d="M24.219,14.993A2.152,2.152,0,0,0,22.8,12.968V5.518l1.419-.593L12.109,0,0,4.925,12.109,9.849,21.381,6.1v6.873a2.156,2.156,0,0,0-.2,3.966L20,20.519l1.346.455L22.09,18.7l.746,2.269,1.346-.455L23,16.934a2.156,2.156,0,0,0,1.216-1.941"
                transform="translate(0 0.026)"
                fill="#b3b4b4"
              />
            </g>
          </g>
          <path
            id="Fill_4"
            data-name="Fill 4"
            d="M0,0V1.868C0,3.907,2.855,5.444,6.641,5.444s6.641-1.538,6.641-3.577V0L6.641,2.585,0,0"
            transform="translate(5.469 8.556)"
            fill="#b3b4b4"
          />
        </g>
      </g>
    </svg>
  )
}
export default Hat
