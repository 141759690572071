import React from 'react'

// Third Party
import { motion } from 'framer-motion'

const loadingCircleVariants = {
  start: {
    y: '0%'
  },
  end: {
    y: '100%'
  }
}

const loadingCircleTransition = {
  duration: 0.5,
  yoyo: Infinity,
  ease: 'easeInOut'
}

const loadingContainer = {
  width: '10rem',
  height: '10rem',
  display: 'flex',
  justifyContent: 'space-around'
}

const loadingCircle = {
  display: 'block',
  width: '2rem',
  height: '2rem',
  backgroundColor: '#283578',
  borderRadius: '1rem'
}

const Loading: React.FC<{}> = () => (
  <div className="h-100 w-100 d-flex justify-content-center align-items-center">
    <motion.div
      style={loadingContainer}
      transition={{
        staggerChildren: 0.1
      }}
      initial="start"
      animate="end"
      className="h-100"
    >
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
    </motion.div>
  </div>
)

export default Loading
