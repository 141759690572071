import * as React from 'react'

function CheckMark() {
  return (
    <svg
      id="check-mark"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clipCheck">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H24V24H0Z" fill="none" />
        </clipPath>
      </defs>
      <path className="no-fill-change" id="Clip_2-2" data-name="Clip 2" d="M0,0H24V24H0Z" fill="none" />
      <g id="check-mark-2" data-name="check-mark" clipPath="url(#clipCheck)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M12,24A12,12,0,0,1,3.515,3.515,12,12,0,1,1,20.485,20.485,11.91,11.91,0,0,1,12,24ZM7.7,10.165h0L5.606,12.257l4.79,4.79,2.1-2.092L18.4,9.049,16.307,6.958,10.4,12.865l-2.7-2.7Z"
          fill="#b3b4b4"
        />
      </g>
    </svg>
  )
}
export default CheckMark
