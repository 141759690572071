import React, { useEffect, useState, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'

// Components
import Header from 'components/Layout/Header'
import Footer from 'components/Layout/Footer'
import SideBar from 'components/SideBar/SideBar'
import PageWrapper from 'components/PageWrapper'
import NoAccountFound from 'components/NoAccountFound'
import Loading from 'components/Loading'
import Toast from 'components/Toast'
import { AnimatePresence } from 'framer-motion'

// Third Party
import styled from 'styled-components'

// Styles
import Theme from 'styles/Theme'

// Hooks
import useKeycloak from 'hooks/useKeycloak'
import useToast from 'hooks/useToast'
// import useAdapter from "hooks/useAdapter";

// Context
import AdapterProvider from 'context/AdapterProvider'
import KeycloakProvider from 'context/KeycloakProvider'
import ToastProvider from 'context/ToastProvider'
import useAdapter from 'hooks/useAdapter'

// Pages
const ResumeAndData = loadable(() => import('./pages/portal/ResumeAndData'))
const DataJobCoach = loadable(() => import('./pages/portal/DataJobCoach'))
const JobAgreementIndication = loadable(() => import('./pages/portal/JobAgreementIndication'))
const Availability = loadable(() => import('./pages/portal/Availability'))
const EducationAndExperience = loadable(() => import('./pages/portal/EducationAndExperience'))
const OtherQuestions = loadable(() => import('./pages/portal/OtherQuestions'))
const AccountInformation = loadable(() => import('./pages/portal/AccountInformation'))
const Resume = loadable(() => import('./pages/portal/Resume'))
const Welcome = loadable(() => import('./pages/portal/Welcome'))
const VacancyAlert = loadable(() => import('./pages/portal/VacancyAlert'))
const ExpirationSignup = loadable(() => import('./pages/portal/ExpirationSignup'))

function App() {
  return (
    <Theme>
      <ToastProvider>
        <KeycloakProvider>
          <AdapterProvider>
            <Switch>
              <PrivateRoute exact path="/" component={() => <Redirect to="/welkom" />} />
              <PrivateRoute path="/welkom" component={Welcome} />
              <PrivateRoute path="/verloopdatum-inschrijving" component={ExpirationSignup} />
              <PrivateRoute path="/persoonlijke-gegevens" component={ResumeAndData} />
              <PrivateRoute path="/gegevens-jobcoach" component={DataJobCoach} />
              <PrivateRoute path="/indicatie-banenafspraak" component={JobAgreementIndication} />
              <PrivateRoute path="/beschikbaarheid" component={Availability} />
              <PrivateRoute path="/opleiding-en-ervaring" component={EducationAndExperience} />
              <PrivateRoute path="/overige-vragen" component={OtherQuestions} />
              <PrivateRoute path="/cv" component={Resume} />
              <PrivateRoute path="/accountgegevens" component={AccountInformation} />
              <PrivateRoute path="/vacature-alert" component={VacancyAlert} />
            </Switch>
          </AdapterProvider>
        </KeycloakProvider>
      </ToastProvider>
    </Theme>
  )
}

const LoadingWrapper = styled.div`
  padding: 100px 0;
`

const PrivateRoute = ({ component: Component, roles = [], ...rest }: any) => {
  const keycloak: any = useKeycloak()
  const adapter: any = useAdapter()
  const toastHook: any = useToast()
  const [isSticky, setSticky] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Suspense fallback={null}>
      <Header setOpen={setOpen} isSticky={isSticky} />
      <div className="container pb-5">
        {Object.keys(adapter.user).length === 0 ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <>
            {adapter.user.status !== 404 &&
            adapter.user.status !== 'no_salesforce_id_found' &&
            adapter.user?.data?.result?.Name !== 'Anonymized' ? (
              <div className="row py-lg-5 py-3 position-relative">
                <div className="col-lg-4">
                  <SideBar open={open} setOpen={setOpen} />
                </div>
                <Route
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...rest}
                  render={(props) => {
                    if (!keycloak.hasRole(roles)) {
                      // return <Redirect to={{ pathname: '/' }} />
                    }

                    return (
                      <div className="col-lg-8">
                        <PageWrapper>
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          <Component {...props} />
                        </PageWrapper>
                      </div>
                    )
                  }}
                />
              </div>
            ) : (
              <NoAccountFound />
            )}
          </>
        )}
        <AnimatePresence>
          {toastHook.toast.status !== 'closed' && <Toast text={toastHook.toast.text} status={toastHook.toast.status} />}
        </AnimatePresence>
      </div>
      <Footer />
    </Suspense>
  )
}

export default App
