import { useContext } from 'react'

import { Context } from '../context/KeycloakProvider'

const useKeycloak = () => {
  const context = useContext(Context)

  return context
}

export default useKeycloak
