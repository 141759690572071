import * as React from 'react'

function File() {
  return (
    <svg
      id="list_2"
      data-name="list 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="15"
      height="20.996"
      viewBox="0 0 15 20.996"
    >
      <defs>
        <clipPath id="clipFileList">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H15V21H0Z" fill="none" />
        </clipPath>
      </defs>
      <g id="list">
        <path className="no-fill-change" id="Clip_2-2" data-name="Clip 2" d="M0,0H15V21H0Z" fill="none" />
        <g id="list-2" data-name="list" clipPath="url(#clipFileList)">
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M4.138,4.288a.15.15,0,0,0,.106-.256L.256.044A.15.15,0,0,0,0,.15V3.538a.75.75,0,0,0,.75.75Z"
            transform="translate(9.75 0.962)"
            fill="#b3b4b4"
          />
          <path
            id="Fill_3"
            data-name="Fill 3"
            d="M13.5,21H1.5A1.5,1.5,0,0,1,0,19.5V1.5A1.5,1.5,0,0,1,1.5,0l6,0a.75.75,0,0,1,.747.75V4.5A2.253,2.253,0,0,0,10.5,6.75H14.25A.751.751,0,0,1,15,7.5v12A1.5,1.5,0,0,1,13.5,21ZM6.375,15.75a.75.75,0,0,0,0,1.5h5.251a.75.75,0,0,0,0-1.5Zm-3,0a.75.75,0,1,0,.287.057A.745.745,0,0,0,3.375,15.75Zm3-3a.75.75,0,0,0,0,1.5h5.251a.75.75,0,0,0,0-1.5Zm-3,0a.75.75,0,1,0,.287.057A.743.743,0,0,0,3.375,12.75Zm3-3a.75.75,0,0,0,0,1.5h5.251a.75.75,0,0,0,0-1.5Zm-3,0a.75.75,0,1,0,.287.057A.745.745,0,0,0,3.375,9.75Z"
            fill="#b3b4b4"
          />
        </g>
      </g>
    </svg>
  )
}
export default File
