import NoConnection from 'components/NoConnection'
import React, { createContext, useState, useEffect } from 'react'
import keycloak from 'utilities/keycloak'

// Elements

export const Context: any = createContext({})

const KeycloakProvider = ({ children }: any) => {
  const [fields, setFields] = useState({
    initialized: false,
    authenticated: false
  })

  useEffect(() => {
    const init = async () => {
      const authenticated = await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
      setFields({
        initialized: true,
        authenticated
      })
    }
    init()
  }, [])

  const hasRole = (roles = []) => {
    if (roles.length === 0) {
      return true
    }

    let response = false

    roles.forEach((role) => {
      const realm = keycloak.hasRealmRole(role)
      const resource = keycloak.hasResourceRole(role)

      if (realm || resource) {
        response = true
      }
    })

    return response
  }

  if (!fields.initialized) {
    return <NoConnection />
  }

  return (
    <Context.Provider
      value={{
        initialized: fields.initialized,
        authenticated: fields.authenticated,
        client: keycloak,
        hasRole
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default KeycloakProvider
