import * as React from 'react'

function Resume() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M908 5105 c-161 -40 -295 -147 -366 -295 -22 -47 -45 -112 -51 -144
-15 -83 -15 -4129 0 -4212 28 -157 153 -324 292 -392 136 -65 33 -62 1777 -62
1747 0 1645 -3 1779 63 76 37 174 128 216 201 16 27 40 78 54 115 l26 66 3
1542 2 1542 -562 3 c-509 4 -568 6 -618 22 -186 61 -325 193 -382 365 -22 64
-23 79 -26 634 l-3 567 -1042 -1 c-850 -1 -1052 -3 -1099 -14z m1408 -651 c15
-11 37 -33 48 -48 21 -27 21 -38 21 -561 0 -523 0 -534 -21 -561 -11 -15 -33
-37 -48 -48 -27 -20 -40 -21 -544 -24 l-517 -3 -45 23 c-26 14 -53 37 -65 58
-19 34 -20 51 -20 555 0 504 1 521 20 555 12 21 39 44 65 58 l45 23 517 -3
c504 -3 517 -4 544 -24z m954 -1602 c19 -9 45 -33 58 -52 20 -29 23 -44 20
-88 -5 -61 -28 -96 -78 -123 -33 -18 -80 -19 -1028 -19 -1077 0 -1035 -2
-1080 55 -46 59 -46 131 0 190 44 56 1 54 1078 55 928 0 997 -1 1030 -18z
m658 -650 c42 -31 64 -74 64 -130 0 -51 -20 -84 -72 -119 l-33 -23 -1327 0
-1327 0 -33 23 c-52 35 -72 68 -72 119 0 72 45 135 107 151 11 3 614 4 1340 3
l1320 -1 33 -23z m-11 -634 c97 -53 99 -205 5 -260 -28 -17 -112 -18 -1362
-18 -1299 0 -1333 0 -1364 19 -42 27 -68 73 -68 125 0 75 43 129 117 147 16 4
617 6 1335 6 1215 -2 1307 -3 1337 -19z m29 -669 c76 -77 57 -196 -39 -240
-41 -18 -88 -19 -1347 -19 l-1306 0 -44 23 c-55 27 -82 71 -82 133 0 53 20 86
72 122 l33 22 1338 -2 1339 -3 36 -36z"
        />
        <path
          d="M1425 4167 c-3 -7 -4 -156 -3 -332 l3 -320 333 -3 332 -2 0 335 0
335 -330 0 c-257 0 -332 -3 -335 -13z"
        />
        <path
          d="M3350 4549 c0 -535 2 -550 61 -622 17 -21 54 -50 83 -65 l51 -27 498
-3 c273 -2 497 0 497 5 0 4 -268 276 -595 603 l-595 595 0 -486z"
        />
      </g>
    </svg>
  )
}
export default Resume
