import * as React from 'react'

function Person() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.996" height="23.061" viewBox="0 0 17.996 23.061">
      <defs>
        <clipPath id="clip-path">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H18V13.176H0Z" transform="translate(0.002)" fill="none" />
        </clipPath>
      </defs>
      <g id="person_2" data-name="person 2" transform="translate(-0.002)">
        <g id="person">
          <g id="Group_3" data-name="Group 3" transform="translate(0 9.885)">
            <path
              className="no-fill-change"
              id="Clip_2-2"
              data-name="Clip 2"
              d="M0,0H18V13.176H0Z"
              transform="translate(0.002)"
              fill="none"
            />
            <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
              <path
                id="Fill_1"
                data-name="Fill 1"
                d="M18,10.881a12.422,12.422,0,0,0-2.658-7.8A8.2,8.2,0,0,0,9,0a8.2,8.2,0,0,0-6.34,3.082A12.415,12.415,0,0,0,0,10.871,21.811,21.811,0,0,0,9,13.18,19.92,19.92,0,0,0,18,10.881Z"
                transform="translate(0.002)"
                fill="#b3b4b4"
              />
            </g>
          </g>
          <path
            id="Fill_4"
            data-name="Fill 4"
            d="M9,4.531A4.5,4.5,0,1,1,4.5,0,4.484,4.484,0,0,1,7.682,1.327,4.546,4.546,0,0,1,9,4.531Z"
            transform="translate(4.5)"
            fill="#b3b4b4"
          />
        </g>
      </g>
    </svg>
  )
}
export default Person
