import React from 'react'
import { useHistory, useLocation } from 'react-router'

// Third Party
import styled, { css } from 'styled-components'

// Components
import SideBarButton from 'components/SideBar/SideBarButton'

// Images
import CheckMark from 'img/CheckMark'
import LockClock from 'img/LockClock'
import File from 'img/File'
import FileList from 'img/FileList'
import Hat from 'img/Hat'
import Lock from 'img/Lock'
import Person from 'img/Person'
import Persons from 'img/Persons'
import Auti from 'img/Auti'
import Resume from 'img/Resume'
import CloseDark from 'img/CloseDark'
import Bell from 'img/Bell'

// Hooks
import useKeycloak from 'hooks/useKeycloak'

const StyledSideBar = styled.div<{ open: boolean }>`
  background-color: ${(props) => props.theme.color.primaryLighter};
  padding: 40px 0;
  min-height: 100%;
  border-radius: 40px;

  @media (max-width: 1199px) {
    padding: 15px 0;
    border-radius: 30px;
  }

  @media (max-width: 991px) {
    padding: 65px 0 15px 0;
    border-radius: 20px;

    ${({ open }) =>
      open
        ? css`
            position: fixed;
            height: 100vh;
            width: 100vw;
            top: 0;
            left: 0;
            z-index: 50;
            background-color: ${({ theme }) => theme.color.light};
            overflow-y: scroll;
          `
        : css`
            display: none;
          `}
  }
`

const LogoutButton = styled.div`
  position: relative;
  bottom: 0;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};
  padding: 12px 80px;
  border-radius: 25px;
  font-weight: ${(props) => props.theme.font.weight.bold};
  transition: all 0.3s ease;
  height: min-content;
  margin: 15px 0 0 0;

  &:hover {
    background-color: ${(props) => props.theme.color.primaryLight};
    color: ${(props) => props.theme.color.primary};
  }
`

const LogoutButtonContainer = styled.div`
  height: 88px;
`

const CloseWrapper = styled.div`
  display: none;
  position: absolute;
  top: 15px;
  right: 30px;

  @media (max-width: 991px) {
    display: block;
  }
`

function SideBar({ open, setOpen }: any) {
  const history = useHistory()
  const location = useLocation()
  const keycloak: any = useKeycloak()

  return (
    <StyledSideBar open={open}>
      <CloseWrapper onClick={() => setOpen(false)}>
        <CloseDark />
      </CloseWrapper>
      <SideBarButton setOpen={setOpen} icon={<Auti />} history={history} location={location} title="Welkom" path="/welkom" />
      <SideBarButton
        setOpen={setOpen}
        icon={<LockClock />}
        history={history}
        location={location}
        title="Verloopdatum inschrijving"
        path="/verloopdatum-inschrijving"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<Bell />}
        history={history}
        location={location}
        title="Vacature alert"
        path="/vacature-alert"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<Person />}
        history={history}
        location={location}
        title="Persoonlijke gegevens"
        path="/persoonlijke-gegevens"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<Persons />}
        history={history}
        location={location}
        title="Gegevens Jobcoach"
        path="/gegevens-jobcoach"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<File />}
        history={history}
        location={location}
        title="Indicatie banenafspraak"
        path="/indicatie-banenafspraak"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<CheckMark />}
        history={history}
        location={location}
        title="Beschikbaarheid"
        path="/beschikbaarheid"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<Hat />}
        history={history}
        location={location}
        title="Opleiding en Ervaring"
        path="/opleiding-en-ervaring"
      />
      <SideBarButton
        setOpen={setOpen}
        icon={<FileList />}
        history={history}
        location={location}
        title="Overige vragen"
        path="/overige-vragen"
      />
      <SideBarButton setOpen={setOpen} icon={<Resume />} history={history} location={location} title="CV" path="/cv" />
      
      <SideBarButton
        setOpen={setOpen}
        icon={<Lock />}
        history={history}
        location={location}
        title="Accountgegevens"
        path="/accountgegevens"
      />
      <LogoutButtonContainer className="d-flex justify-content-center">
        <LogoutButton role="button" tabIndex={-1} onKeyDown={() => keycloak.client.logout()} onClick={() => keycloak.client.logout()}>
          Uitloggen
        </LogoutButton>
      </LogoutButtonContainer>
    </StyledSideBar>
  )
}

export default SideBar
