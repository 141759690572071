import * as React from 'react'

function Lock() {
  return (
    <svg id="lock_1_2" data-name="lock (1) 2" xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21">
      <path
        id="lock_1_"
        data-name="lock (1)"
        d="M14.222,21H1.778A1.766,1.766,0,0,1,0,19.25V8.312a.442.442,0,0,1,.445-.437H1.778V6.125A6.182,6.182,0,0,1,8,0a6.181,6.181,0,0,1,6.222,6.125v1.75h1.334A.442.442,0,0,1,16,8.312V19.25A1.766,1.766,0,0,1,14.222,21ZM8.216,11.571a1.724,1.724,0,0,0-1.73,1.714,1.682,1.682,0,0,0,.708,1.379L6.922,17.1a.423.423,0,0,0,.107.333.434.434,0,0,0,.322.143h1.73a.434.434,0,0,0,.322-.143.427.427,0,0,0,.108-.333l-.273-2.431a1.683,1.683,0,0,0,.707-1.379A1.724,1.724,0,0,0,8.216,11.571ZM8,3A3.563,3.563,0,0,0,4.325,6.429V8.143h7.351V6.429A3.563,3.563,0,0,0,8,3Z"
        fill="#b3b4b4"
      />
    </svg>
  )
}
export default Lock
