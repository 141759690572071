import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledPageWrapper = styled.div`
  padding: 20px 40px;
  min-height: 100%;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.color.secondaryLight};
  position: relative;

  @media (max-width: 1199px) {
    border-radius: 30px;
    padding: 10px 20px;
  }

  @media (max-width: 991px) {
    padding: 15px;
    border-radius: 20px;
  }
`

interface PageWrapperProps {
  children: any
}

function PageWrapper({ children }: PageWrapperProps) {
  return <StyledPageWrapper>{children}</StyledPageWrapper>
}

export default PageWrapper
