import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.span`
  display: inline-block;

  & > div,
  & > button {
    background-color: ${(props) => props.theme.color.secondary};
    border-radius: 25px;
    padding: 10px 30px;
    transition: all 0.3s ease;
    color: ${(props) => props.theme.color.light};
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 15px;
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    & > a,
    & > button {
      background-color: ${(props) => props.theme.color.secondaryLight};
      color: ${(props) => props.theme.color.secondary};
    }
  }
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({ isAnchor = false, isCustom = false, to, children, className = '' }) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimary
