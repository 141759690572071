import React from 'react'

// Components
import ButtonPrimary from 'components/Button/ButtonPrimary'

// Third Party
import { motion } from 'framer-motion'
import styled from 'styled-components'

const StyledNoConnection = styled.div`
  background-color: ${(props) => props.theme.color.light};
`

const Block = styled.div`
  background-color: ${(props) => props.theme.color.light};
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

  & h1 {
    color: ${(props) => props.theme.color.primary};
    font-weight: ${(props) => props.theme.font.weight.extraBold};
    font-size: 40px;
  }

  & p {
    color: ${(props) => props.theme.color.dark};
    font-weight: ${(props) => props.theme.font.weight.bold};
    font-size: 18px;
  }
`

const NoConnection = () => (
  <StyledNoConnection>
    <div className="container min-vh-100 d-flex align-items-center">
      <div className="row justify-content-center w-100">
        <div className="col-lg-5">
          <motion.div initial={{ x: 100, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 8000 }}>
            <Block className="text-center">
              <h1 className="pb-4">Oeps, er is iets verkeerd gegaan</h1>
              <p>Probeer het nog eens, mocht het nog een keer voorkomen? Neem dan contact op met ons.</p>
              <div className="pt-4">
                <ButtonPrimary className="pt-3" to="/">
                  PROBEER OPNIEUW
                </ButtonPrimary>
              </div>
            </Block>
          </motion.div>
        </div>
      </div>
    </div>
  </StyledNoConnection>
)

export default NoConnection
