import * as React from 'react'

function Logo() {
  return (
    <svg width="209" height="41" viewBox="0 0 209 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#logo)">
        <path
          d="M10.8701 12.95C8.33576 12.9826 5.83096 13.4985 3.49006 14.47C2.49006 14.87 1.91006 15.27 1.91006 15.98C1.98552 16.657 2.19275 17.3126 2.52006 17.91C3.10006 19.19 3.42006 19.66 4.12006 19.7C4.57545 19.6718 5.02229 19.5635 5.44006 19.38L5.77006 19.26C7.39069 18.597 9.11945 18.2377 10.8701 18.2C14.4601 18.2 15.9301 19.52 15.9301 22.75V24.11C13.9743 23.5788 11.9567 23.3098 9.93005 23.31C3.86005 23.31 -0.0699463 26.55 -0.0699463 31.57C-0.0699463 36.87 3.83006 40.17 10.1201 40.17C13.7006 40.295 17.2374 39.3517 20.2801 37.46C20.6706 37.2639 20.9914 36.9525 21.1989 36.5678C21.4064 36.1832 21.4906 35.7441 21.4401 35.31V22.65C21.4401 16.12 17.9901 12.95 10.8701 12.95ZM10.5501 35.25C8.21006 35.25 5.42006 34.6 5.42006 31.5C5.42006 29.18 7.33006 27.79 10.5301 27.79C12.3629 27.8235 14.1814 28.1199 15.9301 28.67V33.73C14.3088 34.7007 12.4595 35.2251 10.5701 35.25H10.5501Z"
          fill="#283578"
        />
        <path
          d="M45.79 13.38C43.15 13.38 42.97 13.57 42.97 16.27V33.13C41.2968 34.1068 39.3872 34.605 37.45 34.57C34.01 34.57 32.03 32.75 32.03 29.57V16.31C32.03 13.56 31.84 13.37 29.19 13.37C26.54 13.37 26.39 13.56 26.39 16.31V29.64C26.39 36.23 30.5 40.17 37.39 40.17C40.9842 40.2606 44.5219 39.2638 47.54 37.31C48.54 36.6 48.63 36.44 48.63 35.12V16.31C48.63 13.57 48.45 13.38 45.79 13.38Z"
          fill="#283578"
        />
        <path
          d="M67.56 33.85C67.1026 33.8874 66.6521 33.9849 66.22 34.14C65.1538 34.5497 64.0223 34.7631 62.88 34.77C60.75 34.77 59.68 33.59 59.68 31.26V18.71H65.99C68.56 18.71 68.74 18.54 68.74 16.1C68.74 13.66 68.54 13.38 65.99 13.38H59.68V10.38C59.68 7.62999 59.49 7.42999 56.83 7.42999C55.53 7.42999 54.83 7.42999 54.45 7.85999C54.07 8.28999 54.04 8.96 54.04 10.3V31.18C54.04 36.81 57.12 40.18 62.3 40.18C64.3608 40.1922 66.4025 39.7839 68.3 38.98C68.99 38.68 69.72 38.3 69.72 37.55C69.6712 36.9309 69.5049 36.3268 69.23 35.77C68.69 34.49 68.44 33.85 67.56 33.85Z"
          fill="#283578"
        />
        <path
          d="M75.71 13.38C73.1 13.38 72.91 13.57 72.91 16.25V37.03C72.91 39.53 73.1 39.71 75.71 39.71C78.32 39.71 78.55 39.53 78.55 37.03V16.25C78.55 13.57 78.37 13.38 75.71 13.38Z"
          fill="#283578"
        />
        <path
          d="M97.61 33.85C97.1491 33.8863 96.6951 33.9839 96.2599 34.14C95.1937 34.5497 94.0622 34.7631 92.92 34.77C90.8 34.77 89.72 33.59 89.72 31.26V18.71H96.0399C98.5999 18.71 98.78 18.54 98.78 16.1C98.78 13.66 98.5799 13.38 96.0399 13.38H89.72V10.38C89.72 7.62999 89.5299 7.42999 86.8799 7.42999C85.5699 7.42999 84.8799 7.42999 84.4899 7.85999C84.0999 8.28999 84.08 8.96 84.08 10.3V31.18C84.08 36.81 87.17 40.18 92.34 40.18C94.4008 40.1922 96.4424 39.7839 98.34 38.98C99.03 38.68 99.7699 38.3 99.7699 37.55C99.7154 36.9319 99.5494 36.3289 99.28 35.77C98.74 34.49 98.48 33.85 97.61 33.85Z"
          fill="#F26640"
        />
        <path
          d="M112.4 12.95C109.866 12.9826 107.361 13.4985 105.02 14.47C104.02 14.87 103.44 15.27 103.44 15.98C103.515 16.657 103.723 17.3126 104.05 17.91C104.63 19.19 104.95 19.66 105.65 19.7C106.105 19.6718 106.552 19.5635 106.97 19.38L107.3 19.26C108.921 18.597 110.649 18.2377 112.4 18.2C115.99 18.2 117.46 19.52 117.46 22.75V24.11C115.504 23.5788 113.487 23.3098 111.46 23.31C105.39 23.31 101.46 26.55 101.46 31.57C101.46 36.87 105.36 40.17 111.65 40.17C115.251 40.3071 118.81 39.3632 121.87 37.46C122.246 37.2522 122.55 36.9358 122.743 36.5521C122.935 36.1684 123.008 35.7355 122.95 35.31V22.65C122.95 16.12 119.5 12.95 112.4 12.95ZM112.08 35.25C109.74 35.25 106.95 34.6 106.95 31.5C106.95 29.18 108.86 27.79 112.06 27.79C113.893 27.8235 115.711 28.1199 117.46 28.67V33.73C115.833 34.7041 113.976 35.2286 112.08 35.25Z"
          fill="#F26640"
        />
        <path
          d="M131.03 7.5C128.4 7.5 128.21 7.69 128.21 10.44V37.03C128.21 39.53 128.4 39.71 131.03 39.71C133.66 39.71 133.85 39.53 133.85 37.03V10.44C133.87 7.69 133.67 7.5 131.03 7.5Z"
          fill="#F26640"
        />
        <path
          d="M150.03 12.95C143.65 12.95 139.2 17.04 138.68 23.37C138.561 25.3682 138.561 27.3718 138.68 29.37C139.16 36.01 143.68 40.14 150.44 40.14C153.109 40.1503 155.75 39.5841 158.18 38.48C159.12 38.04 159.67 37.66 159.67 37C159.607 36.3981 159.419 35.8159 159.12 35.29C158.56 34.06 158.17 33.29 157.36 33.29C156.862 33.3461 156.38 33.499 155.94 33.74L155.62 33.88C153.978 34.5809 152.205 34.9219 150.42 34.88C146.48 34.88 144.13 32.73 144.13 29.12V28.83H157.82C160.72 28.83 161.12 28.41 161.12 25.4V24C161.09 17.4 156.64 12.95 150.03 12.95ZM155.79 24.1V24.23C155.785 24.2766 155.785 24.3235 155.79 24.37H144.1C143.992 22.5951 144.58 20.848 145.74 19.5C146.32 18.9603 147.004 18.5445 147.75 18.278C148.497 18.0115 149.289 17.8999 150.08 17.95C150.862 17.9146 151.644 18.041 152.375 18.3215C153.106 18.602 153.772 19.0304 154.33 19.58C154.872 20.1921 155.281 20.9096 155.532 21.6874C155.784 22.4653 155.871 23.2867 155.79 24.1Z"
          fill="#F26640"
        />
        <path
          d="M176.78 12.95C173.178 12.8346 169.62 13.7692 166.54 15.64C166.165 15.8834 165.864 16.2243 165.669 16.6259C165.473 17.0276 165.391 17.475 165.43 17.92V37.03C165.43 39.53 165.62 39.71 168.28 39.71C170.94 39.71 171.07 39.53 171.07 37.03V19.86C172.798 18.9666 174.715 18.5034 176.66 18.51C181.53 18.51 182.06 22.19 182.06 23.77V37.03C182.06 39.53 182.24 39.71 184.88 39.71C187.52 39.71 187.7 39.53 187.7 37.03V23.55C187.7 16.71 183.87 12.95 176.78 12.95Z"
          fill="#F26640"
        />
        <path
          d="M207.87 35.76C207.37 34.49 207.12 33.85 206.24 33.85C205.779 33.8874 205.325 33.9849 204.89 34.14C203.824 34.5497 202.692 34.7631 201.55 34.77C199.43 34.77 198.35 33.59 198.35 31.26V18.71H204.67C207.24 18.71 207.42 18.54 207.42 16.1C207.42 13.66 207.21 13.38 204.67 13.38H198.35V10.38C198.35 7.62999 198.17 7.42999 195.51 7.42999C194.2 7.42999 193.51 7.42999 193.13 7.85999C192.75 8.28999 192.71 8.96 192.71 10.3V31.18C192.71 36.81 195.8 40.18 200.97 40.18C203.031 40.1922 205.072 39.7839 206.97 38.98C207.66 38.68 208.4 38.3 208.4 37.55C208.34 36.9248 208.16 36.3171 207.87 35.76Z"
          fill="#F26640"
        />
        <path
          d="M72.9301 8.48999C72.9297 8.68017 72.9837 8.8665 73.0855 9.02712C73.1874 9.18773 73.3329 9.31598 73.5051 9.39681C73.6772 9.47763 73.8688 9.50769 74.0575 9.48346C74.2461 9.45923 74.4239 9.38171 74.5701 9.25999C76.1366 8.08806 77.642 6.83641 79.0801 5.50999C79.2799 5.30592 79.3974 5.03531 79.4101 4.74999C79.397 4.4618 79.2798 4.18817 79.0801 3.97998C78.0801 2.88999 74.5701 0.239987 74.5701 0.239987C74.4246 0.118803 74.2477 0.0414086 74.06 0.0168082C73.8722 -0.00779213 73.6814 0.0214113 73.5096 0.101021C73.3378 0.180632 73.1921 0.307379 73.0895 0.466519C72.9869 0.625659 72.9316 0.810652 72.9301 0.999985V8.48999Z"
          fill="#283578"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="208.39" height="40.16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default Logo
