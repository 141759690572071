import * as React from 'react'

function File() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22.854" height="23" viewBox="0 0 22.854 23">
      <defs>
        <clipPath id="clipFile">
          <path id="Clip_2" data-name="Clip 2" d="M0,0H17.4V23H0Z" transform="translate(0.003)" fill="none" />
        </clipPath>
      </defs>
      <g id="_006-contract_2" data-name="006-contract 2" transform="translate(-0.003)">
        <g id="_006-contract" data-name="006-contract">
          <g id="Group_4" data-name="Group 4">
            <path
              className="no-fill-change"
              id="Clip_2-2"
              data-name="Clip 2"
              d="M0,0H17.4V23H0Z"
              transform="translate(0.003)"
              fill="none"
            />
            <g id="Group_4-2" data-name="Group 4" clipPath="url(#clipFile)">
              <path
                id="Fill_1"
                data-name="Fill 1"
                d="M4.031,3.927A2.015,2.015,0,0,0,3.442,2.5L1.548.592A2.047,2.047,0,0,0,.115,0H0V4.043H4.031V3.927h0"
                transform="translate(13.358)"
                fill="#b3b4b4"
              />
              <path
                id="Fill_3"
                data-name="Fill 3"
                d="M15.38,23H2.009A2.018,2.018,0,0,1,0,20.978V2.021A2.017,2.017,0,0,1,2.009,0H12.054V4.717a.654.654,0,0,0,.648.674h4.705V8.31l-4.534,4.544a2.708,2.708,0,0,0-.643,1.051l-.639,1.927a1.7,1.7,0,0,0,.227,1.517,1.681,1.681,0,0,0,1.359.7l.325-.018,2.129-.715a2.658,2.658,0,0,0,1.04-.646l.736-.722v5.028A2.027,2.027,0,0,1,15.38,23Zm-6.7-4.133a.674.674,0,0,0,0,1.347H12.7a.674.674,0,0,0,0-1.347ZM3.348,14.824a.674.674,0,0,0,0,1.348h6.675a.674.674,0,0,0,0-1.348Zm0-2.695a.674.674,0,0,0,0,1.347h6.675a.674.674,0,0,0,0-1.347Zm0-2.7a.674.674,0,0,0,0,1.347h6.675a.674.674,0,0,0,0-1.347Zm0-2.7a.674.674,0,0,0,0,1.347H12.7a.674.674,0,0,0,0-1.347Z"
                fill="#b3b4b4"
              />
            </g>
          </g>
          <path
            id="Fill_5"
            data-name="Fill 5"
            d="M.441,2.185,2.188,1.6.6,0,.018,1.759a.336.336,0,0,0,.424.426"
            transform="translate(12.842 14.501)"
            fill="#b3b4b4"
          />
          <g id="Group_9" data-name="Group 9" transform="translate(14.196 6.604)">
            <path
              id="Fill_6"
              data-name="Fill 6"
              d="M0,4.438,1.9,6.352l4.41-4.438L4.41,0,0,4.438"
              transform="translate(0.037 2.35)"
              fill="#b3b4b4"
            />
            <path
              id="Fill_8"
              data-name="Fill 8"
              d="M2.873.374A1.336,1.336,0,0,0,1.924,0,1.339,1.339,0,0,0,.973.375L0,1.354,1.9,3.268l.973-.979a1.362,1.362,0,0,0,0-1.914"
              transform="translate(5.394 0.043)"
              fill="#b3b4b4"
            />
          </g>
          <path id="Fill_10" data-name="Fill 10" d="M.035.065h0" transform="translate(22.449 8.906)" fill="#b3b4b4" />
        </g>
      </g>
    </svg>
  )
}
export default File
