import { useContext } from 'react'

import { Context } from '../context/ToastProvider'

const useToast = () => {
  const context = useContext(Context)

  return context
}

export default useToast
